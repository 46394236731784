import React from "react";
import './Careers.css';
import GoToTop from "../GoToTop/GoToTop";
import MyVideo from "./MyVideo.mp4"




export default function Careers() {
    return (
        <>
            <div className="C">
                <video id="BGV3" autoPlay loop muted playsInline>
                    <source src={MyVideo} type="video/mp4" />
                </video>
                <div className="vido">
                
                <div className="maintitle">LETS PAINT BIG IDEAS TOGETHER !</div>
                {/* <div className="textstyle">BIG IDEAS TOGETHER </div> */}

                <div className="textstyle2">Join us <span style={{ color: "rgb(0 0 90)" }}><b>ON THE RIDE</b></span> </div>

                </div>
            </div>

            <div className='border'></div>
            <div className="lr">
                {/* <div className="left"> */}

                {/* </div> */}
                <div className="right">
                    <div className="title" id="reveal">JOB OPENINGS</div>
                    <div className="title2" id="reveal">Changing the future of e-commerce means changing the way one shops.</div>
                    <div className='description' id="reveal">We at GHARSEE are always looking to add talented and enthusiastic individuals to join our team and help us achieve the best possible outcome</div>


                </div>

            </div>

            <div className="CBG">

                <div id="list">
                    <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <span className="jobtitle">COMPUTER ENGINEER</span>
                                <span>Vadodara, GJ</span>
                            </div>
                            <div class="flip-card-back">
                                <span className="JobD">We are hiring B.TECH Computer Engineers to join our team. You will work alongside other Computer Engineers and report directly to the Project Manager.Your roles and responsibilities will include Evaluating Business processes, anticipating requirements, uncovering areas for improvement, and developing and implementing solutions. To succeed in this role the individual should have a natural analytical way of thinking and be able to explain difficult concepts to non-technical users.




                                </span>
                            </div>
                        </div>
                        <div className="HH">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe3y07dQwobP5R9R5-HmPBHFKVioC2zdgn7FORwrvlwQ0QCnA/viewform" target="_blank" rel="noreferrer"><button className="btn">Apply Now</button></a>
                        </div>
                    </div>
                    <div class="flip-card">
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <span className="jobtitle">Business Analyst</span>
                                <span>Vadodara, GJ</span>
                            </div>
                            <div class="flip-card-back">
                                <span className="JobD">We are hiring Business Analyst to join our team. You will work alongside other business analysts and report directly to the project manager. Your roles and responsibilities will include performing detailed requirment analysis, documenting processes, and user acceptance testing. To succeed in this role the individual should have a natural analytical way of thinking.

                                    <h4>Business Analyst Responsibilities</h4>
                                    <ul>

                                    <li>Evaluating business processes, anticipating requirements,uncovering areas for improvement, and developing and implementing solutions.</li>
                                    <li>Leading ongoing reviews of business processes and developing optimisation strategies.</li>
                                    <li>Staying up-to-date on the latest process and IT advancements to automate and modernise systems.</li>
                                    <li>Conducting meetings and presentations to share ideas and findings.</li>
                                    <li>Performing requirements analysis.</li>
                                    <li>Documenting and communicating the results of our efforts.</li>
                                    <li>Effectively communicating your insights and plans to cross-functional team members and management.</li>
                                    <li>Gathering critical information from meetings with various stakeholders and producing useful reports.</li>
                                    <li>Working closely with clients, technicians, and managerial staff.</li>
                                    <li>Providing leadership, training, coaching, and guidance to junior staff.</li>
                                    <li>Allocating resources and maintaining cost efficiency.</li>
                                    <li>Ensuring solutions meet business needs and requirements.</li>
                                    <li>Performing user acceptance testing.</li>
                                    <li>Managing projects, developing project plans, and monitoring performance.</li>
                                    <li>Updating, implementing, and maintaining procedures.
                                        Prioritising initiatives based on business needs and requirements.</li>
                                    <li>Serving as a liaison between stakeholders and users.</li>
                                    <li>Managing competing resources and priorities.</li>
                                    <li>Monitoring deliverables and ensuring timely completion of projects.</li>
                                    </ul>
                                    
                                    <h4>Business Analyst Requirements:</h4>

                                    <ul>

                                    <li>A bachelor's degree in business/Engineering or related field</li> 
                                    <li>A minimum of 1 years of experience in business analysis or a related field is preferred but freshers can also apply.</li>
                                    <li>Exceptional analytical and conceptual thinking skills.</li>
                                    <li> The ability to influence stakeholders and work closely with them to determine acceptable solutions.</li>
                                    <li>Advanced technical skills.</li>
                                    <li>Excellent documentation skills.</li>
                                    <li>Fundamental analytical and conceptual thinking skills.</li>
                                    <li>Experience creating detailed reports and giving presentations.</li>
                                    <li>Competency in Microsoft applications including Word, Excel, and Outlook.</li>
                                    <li>Excellent planning, organisational, and time management skills.</li>
                                    </ul>


                                </span>
                            </div>
                        </div>
                        <div className="HH">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe3y07dQwobP5R9R5-HmPBHFKVioC2zdgn7FORwrvlwQ0QCnA/viewform" target="_blank" rel="noreferrer"><button className="btn">Apply Now</button></a>
                        </div>
                    </div>
                </div>



            </div>
            <GoToTop />

        </>






    )
}
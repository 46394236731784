import React from "react";
import './CareerH.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';




export default function Gharsee() {
    return (
        <div className="CH">
            <div className="CH1" >
                <div className="title" id="reveal">Careers</div>
                <div className='description'  id="reveal">We’re looking for innovative talent to join our team. See all positions and submit your CV.</div>
            
                
                <Link className="btn" to="/Careers" id="reveal">Careers</Link>
                

            </div>
            <div className="CH2">
				{/* <iframe title="polls" className="qp_iframe" src="https://poll-maker.com/frame4420083x76Ed447D-139"  
            frameBorder="0" 
			
            width="50%"
            height="600px"
            style={{border:0}}>

            </iframe> */}
            <iframe  src="https://poll-maker.com/frame4436079x22e04565-139" seamless="seamless" 
            frameBorder="no" style={{border:0}}
            className="iframe"
            // width="60%"
            // height="600px"
            >
            </iframe>
            
				</div>
           
            
            



        </div>
        







    )
}
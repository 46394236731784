import React from 'react'
import './inter.css'
import $ from 'jquery';
import green2 from './green2.jpeg';


$(window).scroll(function () {
  var scroll = $(window).scrollTop();
  $(".zom img").css({
    transform:
    `translateX( -${scroll / 28}px) `
  });
});


const Inter = () => {
  return (
    <div id='Inter'>
      <div className='border'>he</div>
      <div className='Inter1'>
      <div className="D1">
                <div className="title" id="reveal">TOWARDS A GREENER WORLD</div>
                <div className="title2" id="reveal">You are now saving the planet while shopping from the comfort of your home! Did you just ask how?</div>
                <span className='description'  id="reveal">GHARSEE's lifelike experience in shopping leads to fewer people visiting the store hence reducing carbon emission through their mode of transport and thus helping the planet breathe better! Don’t you feel like a superhero already?</span>
            </div>
                
                <div  className="zom" >
                <img    src={green2} alt="logo" />
            </div>
      </div>
      
      <div className='Inter2'>
        <div className='D4'>
        
        </div>
        <div className='D3'>
          <div className="title " id="reveal">INTERFACE</div>
          <span className='description' id="reveal">With GHARSEE’S true to life interface, you can experience your favourite stores/brands right at the comfort of your home. Now why waste time commuting to the shop when the store can come to you anytime, anywhere !</span>

        </div>
      </div>
      
      



    </div>
  )
}

export default Inter
import React from "react";
import V1 from './V1.jpg';
import './Vision.css';
import $ from 'jquery';

function reveal() {
    var reveals = document.querySelectorAll("#reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 60;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    $(".zoom img").css({
      transform:
        `translateX( -${scroll / 17}px) `
    });
  });
  
  
  

export default function Vision() {
    return (
        <>
        <div id="Vision" >
            <div className="Vision1">
                <div className="title" id="reveal">Vision</div>
                <div className="title2" id="reveal">To change the Way the World shops</div>
                <span className='description'  id="reveal">GHARSEE strives to be the GO TO PLATFORM when it comes to shopping online in 3D. GHARSEE aims to DILUTE the curtain between Physical (in-store) and Online Shopping. </span>
            </div>
                
                <div  className="zoom" >
                <img    src={V1} alt="logo" />
            </div>



        </div>


        </>




    )
}
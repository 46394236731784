import React from "react";
import GoToTop from "../GoToTop/GoToTop";
import './Home.css';
import MyVideo from './MyVideo.mp4'

export default function Home() {
    return (
        <div className="Home">
                
                <video id="BGV1"  autoPlay loop muted playsInline>
                    <source src={MyVideo} type="video/mp4" />
                </video>
            
            <div className="vid">
                <div id="T1">

                    <span className="maintitle"> THE FUTURE OF ECOMMERCE IS HERE</span> 
                    {/* <span className="maintitle">ECOMMERCE IS HERE</span> */}
                    <span className="maintitle2">Discover the <b>BEST</b> shopping experience with <b>GHARSEE !</b></span>
                    

                </div>
            </div>
            

        <GoToTop/>


        </div>







    )
}
import React, { useState } from 'react'
import './NavBar.css'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LG from './LG.png';
import bars from './bars.png'
import close from './close.png'




window.onscroll = function () { scrollFunction() };

function scrollFunction() {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    document.getElementById("NavMenu").style.fontSize = "12.5px";
    document.getElementById("Gharsee").style.fontSize="27px";
    document.getElementById("Gharsee1").style.display = "none";
    document.getElementById("Gharsee2").style.opacity = "100%";
    document.getElementById("Nav").style.height = "50px";
    document.getElementById("LG").style.height= "50px";
    document.getElementById("LG").style.width= "50px";
  } else {
    document.getElementById("NavMenu").style.fontSize = "14px"
    document.getElementById("Gharsee").style.fontSize="30px";
    document.getElementById("Gharsee1").style.display = "";
    document.getElementById("Gharsee2").style.opacity = "0%";
    document.getElementById("Nav").style.height = "70px";
    document.getElementById("LG").style.height= "60px";
    document.getElementById("LG").style.width= "60px";
  }
}


const NavBar = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  const [menuOpened, setMenuOpen] = useState(false)

  return (
    <div id='Nav'>
      <div className='logo'>
      <img  id='LG' src={LG} alt="logo" />

        <div id='logo'>
          <span id='Gharsee'><Link to="/" >GHARSEE</Link></span>
          <span id='Gharsee1'>Abb Har Gharpee Duniya</span>
        </div>
        <span id='Gharsee2'>Abb Har Gharpee Duniya</span>
      </div>
      {(menuOpened === false && mobile === true ? (
        <div
          style={{alignItems: "center", display: "flex"}}
          onClick={() => setMenuOpen(true)}
        ><img className='bars' src={bars} alt="logo" /></div>
        

      ) :
        (
          <ul id='NavMenu'>
            <li onClick={() => setMenuOpen(false)} ><a href='/#abt'><img className='bars' style={{alignItems: "center", display: "flex",marginLeft:"20px"}}src={close} alt="logo" /></a></li>
            
            <li onClick={() => setMenuOpen(false)} ><Link to="/" >Home</Link></li>
            <li onClick={() => setMenuOpen(false)} ><a href='/#abt'>About Us</a></li>
            <li onClick={() => setMenuOpen(false)} ><a href='/#Vision'>Vision</a></li>
            <li onClick={() => setMenuOpen(false)} ><a href='/#why'>Why GHARSEE</a></li>
            <li onClick={() => setMenuOpen(false)} ><a href='/#Inter'>Value Addition</a></li>
            <li onClick={() => setMenuOpen(false)} ><a href='/#footer'>Contact Us</a></li>
            <li onClick={() => setMenuOpen(false)} ><Link to="/Careers" >Careers</Link></li>


          </ul>
        )


      )}





    </div>
  )
}

export default NavBar
import React from "react";
import './Wgharsee.css'
// import vr from './vr.webp';
import WVR from './WVR.mp4'


export default function Gharsee() {
    return (
        <div id="why">
            <video id="BGV2" autoPlay loop muted playsInline={true}>
                <source src={WVR} type="video/mp4" />
            </video>
            <div className="why1">
                <div className="title" id="reveal">WHY GHARSEE ?</div>
                <div className="title2" id="reveal">A different approach, that is <b>IMMERSIVE</b>  and <b>EFFICIENT.</b></div>
                <p className='description' id="reveal">GHARSEE provides IT'S users with a plethora of features that cannot be experienced anywhere else. On top of being technologically advanced GHARSEE is also incessantly working on reducing it's carbon footprints. And best of all, GHARSEE brings the whole world to you at the tip of your fingers.</p>
            </div>
            <div className="why2" >
            
                {/* <img className="Vimg" src={vr} alt="logo" /> */}

            </div>



        </div>







    )
}
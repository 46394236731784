import React from 'react'
import './About.css'




const About = () => {
  return (
    <div id='abt'>
        <div className='abt2'>
            <div className='title' id="reveal">About Us</div>
            <div className='description' id="reveal">
                We are start-up in E-commerce platform. We are striving to bring change in traditional online shopping method by introducing state-of-art 3-dimensional shopping experience that has not been introduced in the market yet. We intend to be the GO-TO platform whenever the buyer wants to shop online. 
            </div>

        </div>

    </div>
  )
}

export default About
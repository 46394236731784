import React from "react";
import FB from './FB.png';
// import YT from './YT.png';
import Insta from './Insta.png';
import Twitter from './Twitter.png';
import LinkedIn from './LinkedIn.png';
import MAKE from "./MAKE.png";

import './Footer.css';
import Sub from "./Sub/Sub";

const Footer = () => {
	return (
		<>
		<div id="footer">

			<div className="footer1">
			
				<div className="Contact">
					<h1 >Contact</h1>
					<div style={{flexDirection: "row", display:"flex",gap:"10px"}}>

					<div style={{flexDirection: "column", display:"flex",gap:"10px"}}>
						<span><b>Phone&nbsp;&nbsp;&nbsp;&nbsp;:</b></span>
						<span><b>Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</b></span>
						<span><b>Address&nbsp;:</b></span>
					</div>
					<div style={{flexDirection: "column",gap:"10px",display:"flex"}}>

					<div>
					<a style={{color:"white"}}  href="tel:"> 0265-3548456</a></div>
					<div>
					<a style={{color:"white"}}  href="mailto:info@gharsee.com"> info@gharsee.com</a>
					</div>
					<div>
					<a style={{color:"white"}}  href="https://goo.gl/maps/azUipYGQtyTMHWyn8" target="_blank" rel="noreferrer"> SF-220,Siddharth Annexe Near Canal,Sama Savli Road, Vadodara-390024 </a></div>
					</div>
					</div>
						
					

				</div>
				<div className="Contact1">
					<h1 style={{textAlign: "center"}}>Leave us a message</h1>
					
					<Sub/>

					
				</div>
				<div className="Contact2">
					<h1 style={{textAlign: "center"}}>Map</h1>
					<div style={{display: "flex"}} >

						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.5158349510567!2d73.20056352919299!3d22.3512370990811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fcffb4304bf17%3A0xcc9228197beb8c9b!2sGHARSEE%20TECH%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1662719857269!5m2!1sen!2sin"
							width="max-content"
							height="max-content"							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen=""
							aria-hidden="false"
							tabIndex="0"
							title="Location"
							/>
					</div>
				</div>
				<div className="Contact3">
				<h1 style={{textAlign: "center"}}>Connect with us</h1>
				<div className="social">
						<a href="https://instagram.com/gharsee_tech?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer"><img className="social-icons" src={Insta} alt="logo" /></a>
						<img className="social-icons" src={LinkedIn} alt="logo" />
						<img className="social-icons" src={FB} alt="logo" />
						<img className="social-icons" src={Twitter} alt="logo" />
						{/* <img className="social-icons" src={YT} alt="logo" /> */}

					</div>
					<img className="Makeinindia"  src={MAKE} alt="logo" />
					

				</div>
				

			</div>
			
			
		</div>
		
	</>
	);
};
export default Footer;

import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import './Sub.css';


export const Sub = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        alert("Thank you for sharing your valuable thought ");
        
        emailjs.sendForm('service_4fibzks', 'template_fojyyzs', form.current, '74RRA2UekECL10kvN')
        .then((result) => {
            console.log(result.text);
            console.log("sent")
            document.getElementById("form").reset();
                
            }, (error) => {
                console.log(error.text);
            });
    };

    return (


        <form id='form' ref={form} onSubmit={sendEmail} >


            <input type="text" name="user_name" placeholder='Name' />



            <input type="email" name="user_email" placeholder='Email' />


            <textarea name="message" placeholder='Message' />

            <input className='btn' type="submit" value="Send" />


            
        </form>


    )
}

export default Sub
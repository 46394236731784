import './App.css';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Vision from './Components/Vision/Vision';
import NavBar from "./Components/NavBar/NavBar";
import Wgharsee from "./Components/Wgharsee/Wgharsee";
import Interface from "./Components/Interface/Inter"
import CareerH from "./Components/CareerH/CareerH"
import Careers from "./Components/Careers/Careers"
import About from "./Components/About/About"
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDivSYUSAa8s5PNKpwKbaW0FzkYTFPCnYU",
  authDomain: "gharseetech.firebaseapp.com",
  projectId: "gharseetech",
  storageBucket: "gharseetech.appspot.com",
  messagingSenderId: "1096127973250",
  appId: "1:1096127973250:web:901ccbf7139acfb95a719b",
  measurementId: "G-P7G58XDTYS"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



function App() {
  return (
    <Router>
      <>
      <NavBar/>
      <Routes>
      <Route path="/Careers" element={<Careers />} />
      <Route path="/" element={<><Home/><About/><Vision/><Wgharsee/><Interface/><CareerH/></>} />
      
     
      </Routes>
      <Footer/>
      </>
    
    </Router>
  );
}

export default App;

